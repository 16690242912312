<template>
  <div>
    <div class="report-user-card">
      <section class="column-wrapper-main">
        <div class="left-column-main">
          <DisplayAvatar :avatar="getAvatar" :width="`4rem`" :height="`4rem`" />
        </div>

        <div class="right-column-main">
          <div>
            <h6 class="font-extrabold text-14px text-muted">
              {{
                $t(
                  'components.reportManagement.details.vehicle.reportedUser.previousRider'
                )
              }}
            </h6>
            <h2 class="font-extrabold text-22px text-oDark">
              {{ details.prev_trip_user.full_name || '--' }}
            </h2>
          </div>

          <div v-if="details.prev_trip_user">
            <div class="points red">
              {{ details.prev_trip_user.deduct_point }}
            </div>
          </div>
        </div>
      </section>

      <section class="column-wrapper-main">
        <div class="left-column-main" />
        <div class="right-column-main">
          <h5 class="text-title">
            {{
              $t(
                'components.reportManagement.details.vehicle.reportedUser.tripID'
              )
            }}
          </h5>

          <div class="text-value">
            <router-link
              v-if="details.prev_trip"
              :to="{
                name: 'ViewTrip',
                params: { id: details.prev_trip.id },
              }"
              target="_blank"
            >
              <div class="font-extrabold text-blue-500 text-14px">
                {{ $truncate(details.prev_trip.id, -5) }}
              </div>
            </router-link>
            <div v-else>
              --
            </div>
          </div>
        </div>
      </section>

      <section class="column-wrapper-main">
        <div class="left-column-main" />
        <div class="right-column-main">
          <h5 class="text-title">
            {{
              $t(
                'components.reportManagement.details.vehicle.reportedUser.tripDate'
              )
            }}
          </h5>

          <h5 class="text-value">
            {{
              details.prev_trip
                ? getFormattedDateTime(details.prev_trip.created_at)
                : '--'
            }}
          </h5>
        </div>
      </section>

      <section class="column-wrapper-main">
        <div class="left-column-main" />
        <div class="right-column-main">
          <h5 class="text-title">
            {{
              $t(
                'components.reportManagement.details.vehicle.reportedUser.parkingPhoto'
              )
            }}
          </h5>

          <section>
            <img
              v-if="details.prev_trip_parking_pic"
              :src="details.prev_trip_parking_pic"
              @click="image_viewing_index = 0"
              alt="prev trip photo"
              class="prev-trip-photo cursor-pointer"
            />
            <div v-else>
              --
            </div>
          </section>
        </div>
      </section>
    </div>
    <CoolLightBox
      effect="fade"
      :items="getTripParkingImage"
      :index="image_viewing_index"
      :useZoomBar="true"
      @close="image_viewing_index = null"
    />
  </div>
</template>

<script>
import CoolLightBox from 'vue-cool-lightbox'
import { getFormattedDateTime } from '@/utils/datetime'

export default {
  name: 'ReportedUserBlock',
  props: {
    details: {
      type: Object,
      required: true,
    },
  },
  components: {
    CoolLightBox,
    DisplayAvatar: () => import('@/components/ui/DisplayAvatar'),
  },
  data() {
    return {
      image_viewing_index: null,
    }
  },
  computed: {
    getAvatar() {
      return this.details.prev_trip_user
        ? this.details.prev_trip_user.profile_pic
        : null
    },
    getTripParkingImage() {
      return [{ src: this.details.prev_trip_parking_pic }]
    },
  },

  methods: {
    getFormattedDateTime,
  },
}
</script>

<style lang="scss" scoped>
@import '$report-user-card';
.prev-trip-photo {
  width: 54px;
  height: 67px;
  border-radius: 7px;
  background-color: #d8d8d8;
  overflow: hidden;
}
</style>
